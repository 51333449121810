<template>
  <div>
    <template>
      <v-card
        v-if="code"
        elevation="24"
        max-width="1200"
        class="mx-auto mt-16"
      >
        <v-list-item class="pt-2 pb-0">
          <router-link to="/">
            <v-list-item-avatar>
              <v-img src="/logo_small.png"></v-img>
            </v-list-item-avatar>
          </router-link>
          <v-list-item-content>
            <v-list-item-title>
              <router-link to="/">
                FantasyKombat
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle>
              <router-link to="/">
                Presentation
              </router-link>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-carousel
          :continuous="false"
          :show-arrows="true"
          reverse-transition="fade-transition"
          transition="fade-transition"
          height="100%"
          class="deck-carousel mt-0 pt-0"
        >
          <div class="pa-4 mt-0 pt-0">
            <!-- start -->
            <v-carousel-item class="deck-start">
              <v-sheet
                height="100%"
                tile
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <div class="text-center pb-16">
                    <v-img
                      :src="require('@/assets/images/logos/nav_logo.png')"
                      max-height="330px"
                      max-width="330px"
                      alt="logo"
                      class="mx-auto mb-2 mt-4"
                    ></v-img>
                    <h2>Recruit, Train, Fight, Profit</h2>
                    <p> A next-gen play-to-earn Fantasy Sports game for MMA / UFC.</P>
                    <br>
                    <br>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>

            <!-- Opportunity -->
            <v-carousel-item>
              <v-sheet
                height="100%"
                tile
              >
                <v-card-title>
                  <h2 class="mx-auto">
                    Opportunity
                  </h2>
                </v-card-title>
                <v-card-text>
                  <p>
                    MMA (Mixed Martial Arts) is one of the worlds fastest growing sports, with an interesting
                    demographic.
                  </p>

                  <p>
                    The UFC alone has 305 million global fans and the youngest fan base among major
                    professional sports in the US,in fact 43% of fans are 18-34 years old.<small>[1]</small>
                  </p>

                  <p>
                    This coincides directly with the most active demographic of NFT/Cryptocurrency purchases
                  </p>
                  <v-img
                    :src="require('@/assets/images/landingpage/nft-demographic.png')"
                    max-height="430px"
                    max-width="430px"
                    alt="logo"
                    class="mx-auto mb-3 screenshot"
                  ></v-img>
                  <p class="text-xs text-center">
                    [2]
                    https://www.statista.com/statistics/1265821/us-nft-user-demographics/
                  </p>

                  <p>
                    Unlike Basketball/Football/Soccer there is a gap in the market for a fully-fledged Fantasy
                    Sports game for MMA fans.
                  </p>

                  <p>
                    The Fantasy Sports market segment is set to reach US$38.6 billion in 2025 at a
                    CAGR of 14.7%.[4]
                  </p>

                  <p>
                    The culture of betting on MMA fights is also significant: 62% of Millennials and 51% of Gen Z have
                    an interest in sports gambling
                    (vs. 45% of the general population) [5]. More than a third of typical MMA fans have used a
                    sport betting website at some point in the last month.
                  </p>

                  <p>
                    The nature of MMA events provides weekly organic & genuine way to promote and align with/piggy-back
                    off other marketing efforts surrounding the real life fight events.
                  </p>

                  <h3 class="text-center mb-3">
                    FantasyKombat is an engaging play-to-earn fantasy sports games, with NFT trading (fighter cards) and
                    cryptocurrency betting for the fastest growing sports base with the a pro crypto
                    demographic.
                  </h3>

                  <p class="text-xs">
                    1.^<a
                      target="_blank"
                      href="https://imgevents.com/property/ufc/ufc/"
                    >https://imgevents.com/property/ufc/ufc/</a><br>
                    2 ^<a
                      target="_blank"
                      href="https://www.statista.com/statistics/1265821/us-nft-user-demographics/"
                    >https://www.statista.com/statistics/1265821/us-nft-user-demographics/</a><br>
                    3.^<a
                      target="_blank"
                      href="https://bleacherreport.com/articles/1113012-ufc-are-children-now-the-most-important-demographic-for-the-ufc-to-focus-on"
                    >https://bleacherreport.com/articles/1113012-ufc-are-children-now-the-most-important-demographic-for-the-ufc-to-focus-on.</a><br>
                    4.^<a
                      target="_blank"
                      href="https://www.sportspromedia.com/news/fantasy-sports-global-market-value-2021-nfl-mlb-nba/"
                    >https://www.sportspromedia.com/news/fantasy-sports-global-market-value-2021-nfl-mlb-nba/</a><br>
                    5.^<a
                      target="_blank"
                      href="https://nvgt.com/blog/navigating-sports-gambling-millennials-gen-z-and-sports-gambling/"
                    >https://nvgt.com/blog/navigating-sports-gambling-millennials-gen-z-and-sports-gambling/</a><br>
                    6.^<a
                      target="_blank"
                      href="https://www.sportspromedia.com/insights/opinions/guest_blog/us-sports-gambling-in-play-data-study-2020/"
                    >https://www.sportspromedia.com/insights/opinions/guest_blog/us-sports-gambling-in-play-data-study-2020/</a><br>
                  </p>
                  <br>
                </v-card-text>
              </v-sheet>
            </v-carousel-item>

            <!-- Solution -->
            <v-carousel-item>
              <v-sheet
                height="100%"
                tile
                class="mx-6"
              >
                <v-card-title>
                  <h2 class="mx-auto">
                    Product
                  </h2>
                </v-card-title>
                <v-card-text>
                  <p>
                    MMA is very different from team and league-based sports, and needed a unique approach.
                    As avid Fantasy Football players we were surprised to find a gap in the market for a fully-fledged
                    fantasy sports game for
                    MMA, so we built it.
                  </p>
                  <p>
                  </p>
                  <p>
                    We also combined the gameplay of StreetFighter, with pokemon style player fighter attributes while
                    still using “real world” MMA
                    results and fighter data for non-fictional attributes.
                  </p>
                  <p>
                    This provides fans with a uniquely engaging game that
                    revolves around the real world fight events.
                  </p>
                  <p>
                  </p>
                  <p>FantasyKombat has 3 game modes:</p>

                  <ul>
                    <li>Fantasy tournaments & fight simulation</li>
                    <li>Arcade Mode (StreetFighter style “Game within Game")</li>
                    <li>Career Mode (Become a player or agent and work your way to the top)</li>
                  </ul>
                  <br>
                  <p>There are 2 in-game currencies:</p>
                  <ul>
                    <li>
                      Silver KombatCoins (free in-game use only)
                    </li>
                    <li>
                      Gold KombatCoin (Solana based SPL) used for premium features and to enter prize
                      pools
                      which can be converted to SOL to withdraw.
                    </li>
                  </ul>
                  <br>
                  <br>
                  <h3>Play-to-earn</h3>
                  <br>
                  <ol>
                    <li>
                      <h4>No loss prize pools</h4>
                      <p>
                        Members buy an entry ticket (never expire) to either private or public league prize pools.
                        Players who earned the most points each week receive a proportion of the prize pool (typically
                        1st/2nd/3rd but custom, per league distribution can be configured by league owners).
                        Players who don't win can re-use their ticket for future weekly prize pool events.
                        This is achievable by working out the cost to maintain the no loss pool (minus our prize pool
                        facilitator fee) and the potential yield generated from all non-winning tickets. (Staked on
                        Solana validators currently 8+%)
                      </p>
                    </li>
                    <li>
                      <h4>Trading Fighter Cards / NFTs</h4>
                      <p>
                        Members can buy/sell MMA Fighters on our MarketPlace.
                        Each fighter card is an NFT that can be traded on the marketplace. Limited edition NFTs are
                        also in circulation for special events.
                      </p>
                      <p>
                        Fighter NFTs can have dynamic attributes linked to both fictional and non-fictional data that
                        can appreciate or depreciate the value of the cards. (i.e fighters real-world results, in-game
                        training to improve attributes)
                      </p>
                      <p>
                        Just like in real life, fighterts stats/attributes can appreciate or depreciate the fighter’s
                        core value.
                        However players can enhance attributes using fictional “in-game training”.
                        This results in many possible permutations of the same player depending on their “team
                        strategy”, ”training” and advisor “power ups”.
                        Trained attributes directly impact fighters abilities/attributes in Arcade & Simulated fight
                        modes.
                      </p>
                      <p>
                        This element of the game adds a layer of strategy that can be lucrative to players who buy
                        upcoming fighters who can increase in value rapidly as they move up the ranks ('training' a
                        fighter is similar to staking and increases their value over time (Fighter NFT Staking/Training
                        is pre-alpha)
                        Members can then choose to sell for a fixed price or start an auction. We take a commission on
                        marketplace trades.
                      </p>
                    </li>
                    <li>
                      <h4>Betting</h4>
                      <p>
                        Members have a range of betting options (mainly around fight outcomes) Initially supporting Sol
                        &
                        KombatCoins, but we plan to accept other cryptocurrencies Q1 2022
                        (we also experimenting with player-2-player betting). We take a facilitator fee on each bet
                      </p>
                    </li>
                  </ol>
                  <br>
                  <br>
                  <br>
                </v-card-text>
              </v-sheet>
            </v-carousel-item>
            <!-- competition-->
            <v-carousel-item>
              <v-sheet
                height="100%"
                tile
              >
                <h2 class="text-center mx-auto mb-2">
                  Features
                </h2>
                <v-simple-table class="mx-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th>FantasyKombat</th>
                        <th>BlockAsset</th>
                        <th>Draft Kings</th>
                        <th>FanDuel</th>
                        <th>Fanatics</th>
                        <th>VerdictMMA</th>
                        <th>Phantasia</th>
                        <th>Krida Fans</th>
                        <th>Sorare</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Supports MMA/Combat Sports</td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Pick'ems
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          No Loss Prize Pools<br>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [b]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [r]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Betting
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [a]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Crypto Payments
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [b]
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Play-to-Earn<br>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [b]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [r]
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          NFTs
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [r]
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Player-to-Player MarketPalce
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [b]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [r]
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Metaverse / Interoperability
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [r]
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                        <td>
                          <v-icon>?</v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Player vs Player Fight Arcade
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [b]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Player vs Player Fight Simulator
                        </td>
                        <td>
                          <v-icon color="success">
                            {{ icons.mdiCheckCircleOutline }}
                          </v-icon>
                          [a]
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                        <td>
                          <v-icon color="error">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="text-center mx-auto">
                  <p class="text-sm">
                    [a] = Alpha |
                    [b] = Beta |
                    [r] = Roadmap
                  </p>
                  <br>
                  <br>
                </div>
              </v-sheet>
            </v-carousel-item>

            <!-- Business Model
            <v-carousel-item class="d-none">
              <v-sheet
                height="100%"
                tile
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <div class="text-h2">
                    Business Model
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            -->

            <!-- end -->
            <v-carousel-item>
              <v-sheet
                height="100%"
                tile
              >
                <v-img
                  :src="require('@/assets/images/logos/nav_logo.png')"
                  max-height="330px"
                  max-width="330px"
                  alt="logo"
                  class="mx-auto mb-2 mt-4"
                ></v-img>
                <h3 class="text-center mx-16">
                  A fully-fledged
                  fantasy
                  sports & crypto betting app for the fastest growing sports base with the a pro crypto
                  demographic.
                </h3>

                <p class="text-center mt-5 mb-12">
                  Contact us: info@fantasykombat.com
                  <br>
                  <br>
                  <a href="https://www.fantasykombat.com/">
                    <v-btn outlined>Landing Page</v-btn>
                  </a>
                </p>
                <br>
              </v-sheet>
            </v-carousel-item>
          </div>
        </v-carousel>
      </v-card>
      <v-card
        v-else
        class="mx-auto mt-16"
      >
        <div class="text-center my-8 py-8">
          <h4>You dont have access to this page</h4>
          <br>
          <a href="https://www.fantasykombat.com/">
            <v-btn outlined>Landing Page</v-btn>
          </a>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import {mdiCheckCircleOutline, mdiCloseCircleOutline} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
      },
    }
  },
  data() {
    return {code: ''}
  },
  mounted() {
    if (this.$route.query.code === 'sqv' || 'vc' || 'dao') {
      this.code = this.$route.query.code
    }
  },
}
</script>

<style lang="scss">
.deck-carousel {
  min-height: 50vh;
}
</style>
